/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';


const GUTTER = 10;
const maxScreenWidth = 740;

export default class StickySidebar extends React.PureComponent {
  static propTypes = {
    topSpacer: PropTypes.number,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    topSpacer: 0,
    className: undefined,
  };

  state = {
    placement: undefined,
    offsetTop: 0,
    offsetLeft: 0,
  };

  componentDidMount() {
    window.document.addEventListener('scroll', this.recalculate);
    window.document.addEventListener('click', this.recalculate);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.recalculate();
  }

  componentDidUpdate() {
    this.recalculate();
  }

  componentWillUnmount() {
    window.document.removeEventListener('scroll', this.recalculate);
    window.document.removeEventListener('click', this.recalculate);
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const container = this.containerEl;

    if (container.parentElement.offsetHeight <= container.offsetHeight) {
      if (this.state.placement !== 'disabled') {
        this.setState({ placement: 'disabled' });
      }
    } else {
      if (this.state.placement === 'disabled') {
        this.setState({ placement: undefined });
      }
      this.recalculate();
    }
  };

  recalculate = () => {
    const screenWidth = document.body.clientWidth;

    if (this.state.placement !== 'disabled') {
      const container = this.containerEl;
      const scrollY = window.scrollY || window.pageYOffset || 0;
      let element = container.parentElement;
      let offsetTop = 0;
      let offsetLeft = 0;
      while (element) {
        offsetTop += element.offsetTop || 0;
        offsetLeft += element.offsetLeft || 0;
        element = element.offsetParent;
      }

      if (this.state.offsetTop !== offsetTop || this.state.offsetLeft !== offsetLeft) {
        this.setState({ offsetTop, offsetLeft });
      }

      if (
        scrollY > this.props.topSpacer + GUTTER
        && scrollY > (this.state.offsetTop + container.parentElement.offsetHeight)
        - container.offsetHeight - (this.props.topSpacer + GUTTER)
        && screenWidth > maxScreenWidth
      ) {
        this.setState({ placement: 'bottom' });
      } else if (
        scrollY > this.state.offsetTop - (this.props.topSpacer + GUTTER)
        && screenWidth > maxScreenWidth
      ) {
        this.setState({ placement: 'fixed' });
      } else {
        this.setState({ placement: 'top' });
      }
    }
  };

  render() {
    const { topSpacer, children, className } = this.props;
    const { placement, offsetLeft } = this.state;
    let containerStyle;

    if (placement === 'fixed') {
      containerStyle = {
        position: 'fixed',
        top: topSpacer + GUTTER,
        left: offsetLeft,
        width: this.containerEl.parentElement.offsetWidth,
      };
    } else if (placement === 'bottom') {
      containerStyle = {
        position: 'absolute',
        left: 0,
        bottom: 0,
        right: 0,
      };
    } else {
      containerStyle = {};
    }

    return (
      <div
        ref={(el) => { this.containerEl = el; }}
        className={classNames(
          'sticky-sidebar',
          className,
        )}
        style={containerStyle}
      >
        {children}
      </div>
    );
  }
}
