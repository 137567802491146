import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import Layout from '../../layouts/SimplePage';
import {
  CustomSection,
  CustomSectionContent,
  CustomSectionStickyHeading,
  CustomSectionStickyHeadingInner,
  CustomSectionStickyItemsWrap,
  CustomSectionItem,
} from '../../components/CustomSection';
import VerticalTextBlock from '../../components/VerticalTextBlock';
import StickySidebar from '../../components/StickySidebar';
import CTA from '../../components/CTA';

import './index.scss';


const GuaranteesPage = () => (
  <Layout
    pageWrapperClassName="guarantees-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Our Guarantees | TakeAwayEssay.com"
      meta={[
        {
          name: 'description',
          content: 'We provide several guarantees to make you feel safe when using our website. Your paper will meet the requirements for a desired grade, otherwise we’ll provide a full refund. By placing an order, you will get an original and properly formatted paper.',
        },

        { property: 'og:title', content: 'Our Guarantees | TakeAwayEssay.com' },
        {
          property: 'og:description',
          content: 'We provide several guarantees to make you feel safe when using our website. Your paper will meet the requirements for a desired grade, otherwise we’ll provide a full refund. By placing an order, you will get an original and properly formatted paper.',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/guarantees.html' },
      ]}
    />

    <CustomSection className="custom-section_guarantees">
      <CustomSectionContent hasStickyElement>

        <CustomSectionStickyHeading className="guarantees-section__heading">
          <StickySidebar topSpacer={169}>
            <CustomSectionStickyHeadingInner className="guarantees-section__heading__inner">
              <VerticalTextBlock
                sectionClassName="guarantees"
                dotsStyle="style-4"
                text="Guarantees"
              />
              <div className="guarantees-section__heading__box">
                <h2 className="guarantees-section__heading__h2">
                  Guarantees
                </h2>
                <p className="guarantees-section__heading__paragraph">
                  We have considered every potential risk. Whatever happens with your order,
                  your money and your private information is safe.
                </p>
              </div>
            </CustomSectionStickyHeadingInner>
          </StickySidebar>
        </CustomSectionStickyHeading>

        <CustomSectionStickyItemsWrap className="guarantees__item-wrap">
          <CustomSectionItem className="guarantees__item guarantees__item_moneyback">
            <Link
              to="/money-back-guarantee.html"
              className="guarantees__item__link-block"
            >
              <h3 className="guarantees__item__heading">
                Money back guarantee
              </h3>
              <p className="guarantees__item__paragraph">
                We always do our best to make our service work according to plan. But we can assure
                you that if anything goes wrong, and there’s nothing we can do to resolve the issue,
                we will give you a refund.
              </p>
              <div className="read-more">
                <span className="read-more__text" data-title="Read more">
                  Read more
                </span>
              </div>
            </Link>
          </CustomSectionItem>
          <CustomSectionItem className="guarantees__item guarantees__item_plagiarism">
            <Link
              to="/plagiarism-free-guarantee.html"
              className="guarantees__item__link-block"
            >
              <h3 className="guarantees__item__heading">
                Plagiarism-free guarantee
              </h3>
              <p className="guarantees__item__paragraph">
                You will get a paper tailored for your particular needs, and it will be
                plagiarism-free. How do we
                know? We check every paper using our plagiarism-detection software before delivering
                it to you. This way, we know the content of your paper is unique.
              </p>
              <div className="read-more">
                <span className="read-more__text" data-title="Read more">
                  Read more
                </span>
              </div>
            </Link>
          </CustomSectionItem>
          <CustomSectionItem className="guarantees__item guarantees__item_privacy">
            <Link
              to="/privacy-policy.html"
              className="guarantees__item__link-block"
            >
              <h3 className="guarantees__item__heading">
                Privacy policy
              </h3>
              <p className="guarantees__item__paragraph">
                All private information you provide us with is kept confidential. We do not sell or
                share it with
                any third party. For a more in-depth explanation of what information we collect from
                you, how we
                process and store it, read our Privacy Policy.
              </p>
              <div className="read-more">
                <span className="read-more__text" data-title="Read more">
                  Read more
                </span>
              </div>
            </Link>
          </CustomSectionItem>
          <CustomSectionItem className="guarantees__item guarantees__item_quality">
            <Link
              to="/quality-assurance.html"
              className="guarantees__item__link-block"
            >
              <h3 className="guarantees__item__heading">
                Quality assurance
              </h3>
              <p className="guarantees__item__paragraph">
                We have a team of quality assurance experts who make sure your paper is written
                according to your
                instructions and meets all the necessary criteria for a desired grade. Find out what
                grading
                criteria we use by visiting our Quality Assurance page.
              </p>
              <div className="read-more">
                <span className="read-more__text" data-title="Read more">
                  Read more
                </span>
              </div>
            </Link>
          </CustomSectionItem>
          <CustomSectionItem className="guarantees__item guarantees__item_revision">
            <Link
              to="/revisions-policy.html"
              className="guarantees__item__link-block"
            >
              <h3 className="guarantees__item__heading">
                Free revisions
              </h3>
              <p className="guarantees__item__paragraph">
                If you get a paper and think your selected writer could’ve done better, you can
                request a revision.
                Tell us what exactly you want to change, and we’ll work on it until it’s perfect. If
                you make a
                request within 7 days from the approval of your order, we will revise it for free.
              </p>
              <div className="read-more">
                <span className="read-more__text" data-title="Read more">
                  Read more
                </span>
              </div>
            </Link>
          </CustomSectionItem>
        </CustomSectionStickyItemsWrap>
      </CustomSectionContent>
    </CustomSection>

    <CTA isOnlyWaves />

  </Layout>
);

export default GuaranteesPage;
